import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Checkbox,
  createTheme,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  ThemeProvider,
  InputLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import cn from 'classnames';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import styles from './FlightSearch.module.scss';
import { FlightSearchAutocomplete } from './FlightSearchAutocomplete/FlightSearchAutocomplete';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import { FieldArray, Form, Formik } from 'formik';
import { BCCDatePicker } from '../DatePicker/BCCDatePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import { PersonInput } from '../PersonInput/PersonInput';
import { object, string, array } from 'yup';
import { httpGet } from '../../utils/httpClient';
import { CABIN_CLASSES } from '../../utils/cabinClasses';
import moment from 'moment';
import qs from 'qs';
import { isWebView } from '../../utils/isWebView';
import { TripTypeSelect } from './TripTypeSelect/TripTypeSelect';
import SyncAlt from '@mui/icons-material/SyncAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const theme = createTheme({
  shape: {
    borderRadius: 12,
  },
  palette: {
    primary: {
      main: '#3C6562',
    },
    secondary: {
      main: '#202020',
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", sans-serif',
  },
});
const FlightSearch = ({
  viewMode,
  airlineName,
  airlineCode,
  airlineClass,
  collapsed,
  toggleSearch,
  flightSearchValueProp,
  widgetMode = false,
  disablePortal = true,
  customHeader,
  customSearchText,
  openInNewTab = false,
  minDate,
  maxDate,
  defaultSortBy,
  searchSource,
}) => {
  const [tripState, setTripState] = useState('round_trip');
  const [collapsedState, setCollapsedState] = useState(collapsed);
  const [searchHotelState, setSearchHotelState] = useState(false);
  const [directOnlyState, setDirectOnlyState] = useState(false);
  const [disableHotelsSearch, setDisableHotelsSearch] = useState(false);
  const [flightSearchValue, setFlightSearchValue] = useState(
    flightSearchValueProp
  );
  const [activeViewMode, setActiveViewMode] = useState(viewMode);
  const [isSideSearch, setIsSideSearch] = useState(false);
  const [isSideSearchMinimized, setIsSideSearchMinimized] = useState(false);
  const [isMainSearch, setIsMainSearch] = useState(false);
  const [openCabinClass, setOpenCabinClass] = useState(false);
  const userStore = useSelector((state) => state.user.value);
  const router = useRouter();

  const fromValidator = object()
    .nullable()
    .required('Please select departure airport')
    .test(
      'from',
      'Departure should be different from arrival',
      function (item) {
        return this.parent.to?.Iata !== item?.Iata;
      }
    );
  const toValidator = object()
    .nullable()
    .required('Please select arrival airport')
    .test('to', 'Arrival should be different from departure', function (item) {
      return this.parent.from?.Iata !== item?.Iata;
    });
  const [searchSchema, setSearchSchema] = useState(
    object().shape({
      from: fromValidator,
      to: toValidator,
      dateRange: object().shape({
        departure: string().nullable().required('Please select departure date'),
        return: string().nullable().required('Please select return date'),
      }),
    })
  );
  const validateHotelSearchState = (val) => {
    if (
      moment(val.return, 'YYYY-MM-DD').diff(
        moment(val.departure, 'YYYY-MM-DD'),
        'days'
      ) > 30 ||
      moment(val.return, 'YYYY-MM-DD').diff(
        moment(val.departure, 'YYYY-MM-DD'),
        'days'
      ) === 0
    ) {
      setSearchHotelState(false);
      setDisableHotelsSearch(true);
    } else {
      setDisableHotelsSearch(false);
    }
  };
  useEffect(() => {
    if (flightSearchValue) {
      validateHotelSearchState(flightSearchValue.dateRange);
      setValidationSchema(flightSearchValue.tripState);
      setTripState(flightSearchValue.tripState);
    }
  }, [flightSearchValue]);
  useEffect(() => {
    if (flightSearchValueProp) {
      setFlightSearchValue(flightSearchValueProp);
    }
  }, [flightSearchValueProp]);

  useEffect(() => {
    if (router.query['stops'] && router.query['stops'] === '0') {
      setDirectOnlyState(true);
    }
  }, [router]);

  useEffect(() => {
    setIsSideSearch(viewMode === 'sideSearch');
    setIsSideSearchMinimized(viewMode === 'sideSearchMinimized');
    setIsMainSearch(viewMode === 'mainSearch');
    setActiveViewMode(viewMode);
    console.log(viewMode);
  }, [viewMode]);

  function setValidationSchema(tripType) {
    if (tripType === 'multi_city') {
      setSearchHotelState(false);
      setDisableHotelsSearch(true);
      setSearchSchema(
        object().shape({
          multi_city: array().of(
            object().shape({
              from: fromValidator,
              to: toValidator,
              departure: string()
                .nullable()
                .required('Please select departure date'),
            })
          ),
        })
      );
    } else if (tripType === 'round_trip') {
      setDisableHotelsSearch(false);
      setSearchSchema(
        object().shape({
          from: fromValidator,
          to: toValidator,
          dateRange: object().shape({
            departure: string()
              .nullable()
              .required('Please select departure date'),
            return: string().nullable().required('Please select return date'),
          }),
        })
      );
    } else {
      setDisableHotelsSearch(false);
      setSearchSchema(
        object().shape({
          from: fromValidator,
          to: toValidator,
          departure: string()
            .nullable()
            .required('Please select departure date'),
        })
      );
    }
  }

  function handleTripTypeChange(value, handleChange, values) {
    patchFormValue(value, values);
    setValidationSchema(value);
    setTripState(value);
  }

  function patchFormValue(tripType, values) {
    if (values.passengers.length) {
      values.passengers.children = values.passengers[0].childrenAges;
      values.passengers.adults = values.passengers[0].adults;
    }
    switch (tripType) {
      case 'one_way':
        setFlightSearchValue({
          ...values,
          tripState: tripType,
          passengers: {
            adults: values.passengers.adults,
            children: values.passengers.children,
          },
          departure: values.departure
            ? values.departure
            : values.dateRange.departure,
        });
        break;
      case 'round_trip':
        setFlightSearchValue({
          ...values,
          tripState: tripType,
          passengers: {
            adults: values.passengers.adults,
            children: values.passengers.children,
          },
          dateRange: {
            ...values.dateRange,
            departure: values.dateRange.departure
              ? values.dateRange.departure
              : values.departure,
          },
        });
        break;
      case 'multi_city':
        setFlightSearchValue({
          ...values,
          tripState: tripType,
          passengers: {
            adults: values.passengers.adults,
            children: values.passengers.children,
          },
          multi_city: [
            {
              from: values.from,
              to: values.to,
              departure: values.multi_city && values.multi_city[0]?.departure
                ? values.multi_city[0]?.departure
                : values.departure
                ? values.departure
                : values.dateRange.departure,
              airlineType: 'all',
            },
          ],
        });
        break;
    }
  }

  const toggleCollapsedSearch = (event) => {
    event.preventDefault();
    toggleSearch();
  };

  const executeSearch = (values) => {
    let query = {};
    query.cabinClass = values.cabinClass;
    query.adults = Array.isArray(
      Array.isArray(values.passengers)
        ? values.passengers[0]
        : values.passengers
    )
      ? (Array.isArray(values.passengers)
          ? values.passengers[0]
          : values.passengers)[0].adults
      : (Array.isArray(values.passengers)
          ? values.passengers[0]
          : values.passengers
        ).adults;
    if (
      (Array.isArray(values.passengers)
        ? values.passengers[0]
        : values.passengers
      ).children?.length
    ) {
      query.children = query.children = values.passengers.children;
    }
    query.qatarOnly = 0;
    let analyticsJourneys = [];
    switch (tripState) {
      case 'round_trip':
        analyticsJourneys = [
          {
            originCountry: values.from.CountryCode,
            originCity: values.from.city,
            destinationCountry: values.to.CountryCode,
            destinationCity: values.to.city,
          },
          {
            originCountry: values.to.CountryCode,
            originCity: values.to.city,
            destinationCountry: values.from.CountryCode,
            destinationCity: values.from.city,
          },
        ];
        query.segments = [
          {
            origin: values.from.Iata,
            destination: values.to.Iata,
            departure: values.dateRange.departure,
            exactAirport: !values.from?.Name.includes('All airports'),
          },
          {
            origin: values.to.Iata,
            destination: values.from.Iata,
            departure: values.dateRange.return,
            exactAirport: !values.to.Name.includes('All airports'),
          },
        ];
        break;
      case 'one_way':
        analyticsJourneys = [
          {
            originCountry: values.from.CountryCode,
            originCity: values.from.city,
            destinationCountry: values.to.CountryCode,
            destinationCity: values.to.city,
          },
        ];
        query.segments = [
          {
            origin: values.from.Iata,
            destination: values.to.Iata,
            departure: values.departure,
            exactFrom: !values.from?.Name.includes('All airports'),
            exactTo: !values.to.Name.includes('All airports'),
          },
        ];

        break;
      case 'multi_city':
        analyticsJourneys = values.multi_city?.map((destination) => {
          return {
            originCountry: destination.from.CountryCode,
            originCity: destination.from.city,
            destinationCountry: destination.to.CountryCode,
            destinationCity: destination.to.city,
          };
        });
        query.segments = values.multi_city?.map((destination) => {
          return {
            origin: destination.from.Iata,
            destination: destination.to.Iata,
            departure: destination.departure,
            exactFrom: !destination.from?.Name.includes('All airports'),
            exactTo: !destination.to.Name.includes('All airports'),
          };
        });
        break;
    }
    if (values.airlineType !== 'all') {
      query.airlines = airlineCode;
    }

    if (directOnlyState) {
      query.stops = 0;
    }

    if (defaultSortBy) {
      query.sortBy = defaultSortBy;
    }

    const tagManagerArgs = {
      dataLayer: {
        event: 'flightSearch',
        flightType: tripState,
        journeys: analyticsJourneys,
        numGuests:
          query.children?.length > 0
            ? +query.adults + query.children?.length
            : +query.adults,
        numAdults: +query.adults,
        numChildren: +query.children?.length,
        userCountry: userStore?.country.toUpperCase(),
        pageType: router?.pathname,
        loginStatus: userStore.userid ? 'logged in' : 'not logged in',
        membershipId: userStore?.membershipNumber,
        searchSource: searchSource,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    if (widgetMode) {
      window.parent.postMessage(
        JSON.stringify({
          searchUrl: decodeURI(`/flightsearch?${qs?.stringify(query || {})}`),
        }),
        '*'
      );
    } else {
      query.tripState = tripState;
      if (searchHotelState) {
        const win = window.open();
        httpGet(`/api/hotel/autocomplete?search=${values.to.city}`).then(
          (response) => {
            response.json().then((result) => {
              const hotelQuery = {};
              if (result.length) {
                if (tripState === 'round_trip') {
                  hotelQuery.dateRange = values.dateRange;
                } else if (tripState === 'one_way') {
                  hotelQuery.dateRange = {
                    departure: values.departure,
                    return: moment(values.departure)
                      .add(1, 'day')
                      .format('yyyy-MM-DD'),
                  };
                } else {
                  hotelQuery.dateRange = {
                    departure: values.multi_city[0]?.departure,
                    return: moment(values.multi_city[0]?.departure)
                      .add(1, 'day')
                      .format('yyyy-MM-DD'),
                  };
                }
                hotelQuery.autocomplete = result.find(
                  (s) => s.s === 1 && (s.t === 'cities' || s.t === 'countries')
                );
                hotelQuery.visitors = [];
                hotelQuery.visitors.push({
                  adults: values.passengers.adults || 1,
                  ...(values.passengers.children?.length && {
                    childrenAges: values.passengers.children.map((c) => +c),
                  }),
                });
                hotelQuery.search = 1;
                if (query) {
                  win.location = decodeURI(
                    `/flightsearch?${qs.stringify(query)}`
                  );
                } else {
                  console.error('User have no query');
                  win.location = decodeURI(`/flightsearch`);
                }
                if (hotelQuery) {
                  document.location = decodeURI(
                    `/hotelsearch?${qs.stringify(hotelQuery)}`
                  );
                } else {
                  console.error('User have no hotelQuery');
                  document.location = decodeURI(`/hotelsearch`);
                }
              }
            });
          }
        );
      } else {
        window.open(
          decodeURI(`/flightsearch?${qs?.stringify(query)}`),
          openInNewTab ? '_blank' : '_self'
        );
        window.focus();
      }
    }
  };
  const handleHotelSearchChange = (event) => {
    setSearchHotelState(event.target.checked);
  };

  const handleDirectOnlyChange = (event) => {
    setDirectOnlyState(event.target.checked);
  };

  function handleSwapDirections(values, setFieldValue, index) {
    if (index !== undefined) {
      const from = values.multi_city[index].from;
      const to = values.multi_city[index].to;
      values.multi_city[index].from = to;
      values.multi_city[index].to = from;
      setFlightSearchValue({ ...values });
    } else {
      setFieldValue('from', values.to);
      setFieldValue('to', values.from);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div
        className="w-full overflow-auto"
        onClick={() => setCollapsedState(false)}
      >
        {airlineClass && !collapsed && (
          <div className="px-4 text-center lg:pt-7 pt-4 font-garamond">
            {!customHeader && isSideSearchMinimized && (
              <>
                {airlineName && (
                  <>
                    <span className={styles.searchHeading}>
                      Find the best offers for
                    </span>
                    <span className={styles.airlineCredentials}>
                      <span className="mb-3">
                        {airlineName}{' '}
                        <span className="block mt-2">&amp; more</span>
                      </span>
                    </span>
                  </>
                )}

                {!airlineName && (
                  <span className={styles.searchHeading}>
                    Search premium flight offers
                  </span>
                )}
              </>
            )}
            {!customHeader && isSideSearch && (
              <>
                {airlineName && (
                  <>
                    <span className={styles.searchHeading}>
                      Find the best offers for
                    </span>
                    <span className={styles.airlineCredentials}>
                      <span className="mb-3">
                        {airlineName}{' '}
                        <span className="block mt-2">&amp; more</span>
                      </span>
                    </span>
                  </>
                )}

                {!airlineName && (
                  <span className={styles.searchHeading}>
                    Search premium flight offers
                  </span>
                )}
              </>
            )}
            {!customHeader && isMainSearch && (
              <>
                <span className={cn(styles.airlineCredentials, 'mb-4')}>
                  {airlineName
                    ? `Find the best offers for ${airlineName} & more`
                    : 'Search premium flight offers'}
                </span>
              </>
            )}

            {customHeader && (
              <span className={styles.airlineCredentials}>{customHeader}</span>
            )}
          </div>
        )}
        {collapsed && (
          <div className="px-4 text-center pt-7 font-garamond relative">
            <span className={styles.searchHeading}>
              Search premium flight offers
            </span>
            <IconButton
              color="inherit"
              onClick={toggleCollapsedSearch}
              aria-label="close"
              className="absolute top-0 right-0"
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}
        <Formik
          enableReinitialize={true}
          initialValues={
            flightSearchValue || {
              from: '',
              to: '',
              trip: tripState,
              dateRange: {
                departure: null,
                return: null,
              },
              departure: null,
              airlineType: 'all',
              passengers: {
                adults: 1,
                children: [],
              },
              multi_city: [
                {
                  from: '',
                  to: '',
                  departure: null,
                  airlineType: 'all',
                },
              ],
              cabinClass: 'BIZ',
            }
          }
          validationSchema={searchSchema}
          onSubmit={(values) => {
            executeSearch(values);
          }}
        >
          {({ setFieldValue, handleChange, values, errors, touched }) => (
            <Form>
              <div
                className={cn(styles.searchContainer, {
                  [styles.sideSearch]: isSideSearch,
                  [styles.sideSearchMinimized]: isSideSearchMinimized,
                  [styles.mainSearch]: isMainSearch,
                })}
              >
                {!collapsedState && isSideSearchMinimized && (
                  <div className={styles.tripTypeContainer}>
                    <Select
                      fullWidth
                      defaultValue={'round_trip'}
                      onChange={(e) =>
                        handleTripTypeChange(
                          e.target.value,
                          handleChange,
                          values
                        )
                      }
                    >
                      <MenuItem value={'round_trip'}>Round trip</MenuItem>
                      <MenuItem value={'one_way'}>One way</MenuItem>
                      <MenuItem value={'multi_city'}>Multi city</MenuItem>
                    </Select>
                  </div>
                )}
                {!collapsedState && isSideSearch && (
                  <div
                    className={cn(styles.tripTypeContainer, {
                      'flex-col': isSideSearch || isSideSearchMinimized,
                      'flex items-center': isMainSearch,
                    })}
                  >
                    {!isSideSearchMinimized && (
                      <TripTypeSelect
                        handleTripTypeChange={handleTripTypeChange}
                        tripState={tripState}
                        values={values}
                        handleChange={handleChange}
                        viewMode={activeViewMode}
                      />
                    )}
                  </div>
                )}
                {!collapsedState && (
                  <div
                    className={cn(styles.searchType, {
                      'flex-col': isSideSearch || isSideSearchMinimized,
                      'flex mb-4': isMainSearch,
                    })}
                  >
                    {isMainSearch && (
                      <div
                        className={cn(
                          styles.tripTypeContainer,
                          'flex items-center'
                        )}
                      >
                        <TripTypeSelect
                          handleTripTypeChange={handleTripTypeChange}
                          tripState={tripState}
                          values={values}
                          handleChange={handleChange}
                          viewMode={activeViewMode}
                        />
                      </div>
                    )}
                    <div className={cn(styles.personAndClassColumn)}>
                      <PersonInput
                        value={
                          flightSearchValue
                            ? flightSearchValue.passengers
                            : undefined
                        }
                        name="passengers"
                        setFieldValue={setFieldValue}
                        disablePortal={disablePortal}
                      />
                      {!isSideSearchMinimized && (
                        <FormControl
                          fullWidth
                          className={cn(
                            isSideSearchMinimized ? 'my-4' : 'ml-2'
                          )}
                          sx={{
                            paddingRight: '3px',
                          }}
                        >
                          <InputLabel id="cabin-label">Cabin class</InputLabel>
                          <Select
                            name={'cabinClass'}
                            open={openCabinClass}
                            label="Cabin class"
                            labelId="cabin-label"
                            id={'cabinClass'}
                            onClose={() => setOpenCabinClass(false)}
                            onOpen={() => setOpenCabinClass(true)}
                            value={
                              values.cabinClass || flightSearchValue?.cabinClass
                            }
                            onChange={(p) => {
                              handleChange(p);
                              setOpenCabinClass(false);
                            }}
                            IconComponent={() => (
                              <InputAdornment position="end">
                                <ExpandMoreIcon
                                  onClick={() =>
                                    setOpenCabinClass(!openCabinClass)
                                  }
                                  sx={{ cursor: 'pointer' }}
                                />
                              </InputAdornment>
                            )}
                            sx={{
                              '.MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input':
                                {
                                  paddingRight: '3px',
                                },
                            }}
                          >
                            {CABIN_CLASSES.map((cabin) => {
                              return (
                                <MenuItem key={cabin.value} value={cabin.value}>
                                  {cabin.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      )}
                    </div>
                  </div>
                )}
                {tripState !== 'multi_city' && (
                  <>
                    <div
                      className={cn({
                        [styles.sideInputContainer]:
                          isSideSearch || isSideSearchMinimized,
                        'mt-4': isSideSearch,
                        [styles.mainAutocompleteInputContainer]: isMainSearch,
                      })}
                    >
                      <FlightSearchAutocomplete
                        setFieldValue={setFieldValue}
                        name="from"
                        label="From"
                        icon={<FlightTakeoffIcon />}
                        error={!!(touched.from && errors.from)}
                        patch={{ value: values.from, exact: values.exactFrom }}
                      />
                      {touched.from && errors.from && (
                        <div className={styles.errorMessage}>{errors.from}</div>
                      )}
                    </div>
                    <div
                      className={styles.swapDirections}
                      onClick={() =>
                        handleSwapDirections(values, setFieldValue)
                      }
                    >
                      <SyncAlt />
                    </div>
                    <div
                      className={cn({
                        [styles.sideInputContainer]:
                          isSideSearch || isSideSearchMinimized,
                        [styles.mainAutocompleteInputContainer]: isMainSearch,
                      })}
                      style={{
                        marginTop:
                          isSideSearch || isSideSearchMinimized
                            ? '-1rem'
                            : 'unset',
                      }}
                    >
                      <FlightSearchAutocomplete
                        setFieldValue={setFieldValue}
                        name="to"
                        label="To"
                        icon={<FlightLandIcon />}
                        error={!!(touched.to && errors.to)}
                        patch={{ value: values.to, exact: values.exactTo }}
                      />
                      {touched.to && errors.to && (
                        <div className={styles.errorMessage}>{errors.to}</div>
                      )}
                    </div>
                  </>
                )}

                {tripState === 'round_trip' && (
                  <>
                    {!collapsedState && (
                      <>
                        <div
                          className={cn(
                            {
                              [styles.inputRangeContainer]:
                                isSideSearch || isSideSearchMinimized,
                              [styles.mainInputRangeContainer]: isMainSearch,
                              flex: isMainSearch,
                              'flex-col': isSideSearch,
                            },
                            'p-0 justify-center'
                          )}
                        >
                          <BCCDatePicker
                            mode="range"
                            labelStart="Departure"
                            labelEnd="Return"
                            setFieldValue={(name, val) => {
                              setFieldValue('dateRange', val);
                              validateHotelSearchState(val);
                            }}
                            name="dateRange"
                            viewMode={
                              isSideSearchMinimized ? 'vertical' : 'horizontal'
                            }
                            rangeValue={[
                              values.dateRange.departure,
                              values.dateRange.return,
                            ]}
                            minDate={minDate || undefined}
                            maxDate={maxDate || undefined}
                            error={
                              !!(
                                touched &&
                                touched.dateRange &&
                                errors.dateRange
                              )
                            }
                            errorMessages={
                              touched &&
                              touched.dateRange &&
                              errors &&
                              errors.dateRange
                            }
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
                {tripState === 'one_way' && (
                  <>
                    <div
                      className={cn({
                        [styles.sideInputContainer]:
                          isSideSearch || isSideSearchMinimized,
                        'mt-4': isSideSearch,
                        [styles.mainDepartureInputContainer]: isMainSearch,
                      })}
                    >
                      <BCCDatePicker
                        mode="single"
                        labelStart="Departure"
                        setFieldValue={setFieldValue}
                        name="departure"
                        minDate={new Date()}
                        value={values.departure}
                        error={!!(touched.departure && errors.departure)}
                      />
                      {touched.departure && errors.departure && (
                        <div className={styles.errorMessage}>
                          {errors.departure}
                        </div>
                      )}
                    </div>
                  </>
                )}
                {tripState === 'multi_city' && (
                  <>
                    <div className={styles.multiCityContainer}>
                      <FieldArray name="multi_city">
                        {(arrayHelpers) => (
                          <>
                            {values.multi_city?.map((city, index) => (
                              <div
                                className={cn({
                                  'w-full mt-4': isSideSearch,
                                })}
                                key={index}
                              >
                                <div
                                  className={cn({
                                    flex: isMainSearch,
                                    'flex-col items-center':
                                      isSideSearch || isSideSearchMinimized,
                                  })}
                                >
                                  <div
                                    className={cn({
                                      [styles.sideInputContainer]:
                                        isSideSearch || isSideSearchMinimized,
                                      [styles.mainInputContainer]: isMainSearch,
                                    })}
                                  >
                                    <FlightSearchAutocomplete
                                      setFieldValue={setFieldValue}
                                      name={`multi_city.${index}.from`}
                                      label="From"
                                      icon={<FlightTakeoffIcon />}
                                      patch={{
                                        value: values.multi_city[index].from,
                                        exact:
                                          values.multi_city[index].exactFrom,
                                      }}
                                      error={
                                        !!(
                                          touched?.multi_city?.[index]?.from &&
                                          errors?.multi_city?.[index]?.from
                                        )
                                      }
                                    />
                                    {touched?.multi_city?.[index]?.from &&
                                      errors?.multi_city?.[index]?.from && (
                                        <div className={styles.errorMessage}>
                                          {errors?.multi_city?.[index]?.from}
                                        </div>
                                      )}
                                  </div>
                                  {!collapsedState && (
                                    <div
                                      className={styles.swapDirections}
                                      onClick={() =>
                                        handleSwapDirections(
                                          values,
                                          setFieldValue,
                                          index
                                        )
                                      }
                                    >
                                      <SyncAlt />
                                    </div>
                                  )}
                                  <div
                                    className={cn({
                                      [styles.sideInputContainer]:
                                        isSideSearch || isSideSearchMinimized,
                                      [styles.mainInputContainer]: isMainSearch,
                                      'ml-4': isMainSearch,
                                    })}
                                    style={{
                                      marginTop:
                                        isSideSearch || isSideSearchMinimized
                                          ? '-1rem'
                                          : 'unset',
                                    }}
                                  >
                                    <FlightSearchAutocomplete
                                      setFieldValue={setFieldValue}
                                      name={`multi_city.${index}.to`}
                                      label="To"
                                      icon={<FlightLandIcon />}
                                      patch={{
                                        value: values.multi_city[index].to,
                                        exact: values.multi_city[index].exactTo,
                                      }}
                                      error={
                                        !!(
                                          touched?.multi_city?.[index]?.to &&
                                          errors?.multi_city?.[index]?.to
                                        )
                                      }
                                    />
                                    {touched?.multi_city?.[index]?.to &&
                                      errors?.multi_city?.[index]?.to && (
                                        <div className={styles.errorMessage}>
                                          {errors?.multi_city?.[index]?.to}
                                        </div>
                                      )}
                                  </div>
                                  <div
                                    className={cn({
                                      [styles.sideInputContainer]:
                                        isSideSearch || isSideSearchMinimized,
                                      'mt-4': isSideSearch,
                                      [styles.mainInputContainer]: isMainSearch,
                                    })}
                                  >
                                    <BCCDatePicker
                                      mode="single"
                                      labelStart="Departure"
                                      setFieldValue={setFieldValue}
                                      name={`multi_city.${index}.departure`}
                                      minDate={
                                        index >= 1 &&
                                        values.multi_city[index - 1].departure
                                          ? new Date(
                                              values.multi_city[
                                                index - 1
                                              ].departure
                                            )
                                          : undefined
                                      }
                                      value={values.multi_city[index].departure}
                                      error={
                                        !!(
                                          touched?.multi_city?.[index]
                                            ?.departure &&
                                          errors?.multi_city?.[index]?.departure
                                        )
                                      }
                                    />
                                    {touched?.multi_city?.[index]?.departure &&
                                      errors?.multi_city?.[index]
                                        ?.departure && (
                                        <div className={styles.errorMessage}>
                                          {
                                            errors?.multi_city?.[index]
                                              ?.departure
                                          }
                                        </div>
                                      )}
                                  </div>
                                </div>
                                {!!index && (
                                  <Button
                                    variant="outlined"
                                    endIcon={<DeleteIcon />}
                                    color="secondary"
                                    className="mt-2"
                                    sx={{ textTransform: 'unset' }}
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    Remove Flight
                                  </Button>
                                )}
                                <Divider
                                  variant="middle"
                                  sx={{
                                    marginTop: '.75rem',
                                    marginBottom: '.75rem',
                                  }}
                                />
                              </div>
                            ))}
                            <Button
                              variant="contained"
                              endIcon={<AddIcon />}
                              type="button"
                              sx={{ textTransform: 'unset' }}
                              onClick={() =>
                                arrayHelpers.push({
                                  from: '',
                                  to: '',
                                  departure: '',
                                  airlineType: 'all',
                                })
                              }
                            >
                              Add another flight
                            </Button>
                          </>
                        )}
                      </FieldArray>
                    </div>
                  </>
                )}
                <div
                  className={cn({
                    [styles.mainSubmitContainer]: isMainSearch,
                    [styles.mainSubmitContainerSide]:
                      isSideSearch || isSideSearchMinimized,
                  })}
                >
                  {!!airlineName && (
                    <div
                      className={cn({
                        'flex-col my-2': isSideSearch || isSideSearchMinimized,
                        'flex ml-6': isMainSearch,
                      })}
                    >
                      <FormControl
                        className="w-full block"
                        component="fieldset"
                      >
                        <FormLabel component="legend">Search for</FormLabel>
                        <RadioGroup
                          row
                          aria-label="airlineType"
                          name="airlineType"
                          defaultValue={flightSearchValue?.airlineType || 'all'}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="all"
                            control={<Radio />}
                            label={`All airlines`}
                          />
                          <FormControlLabel
                            value={airlineName}
                            control={<Radio />}
                            label={`${airlineName} only`}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  )}
                  <div
                    className={cn(styles.mainSubmitMulticityButtonContainer)}
                  >
                    <Button
                      className={cn('h-full')}
                      sx={{ textTransform: 'unset' }}
                      fullWidth
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      {customSearchText && customSearchText}
                      {!customSearchText && 'Search'}
                    </Button>
                  </div>

                  <div>
                    {!widgetMode && (
                      <FormControlLabel
                        className={'mt-1 lg:mt-0'}
                        disabled={isWebView()}
                        control={
                          <Checkbox
                            onChange={handleDirectOnlyChange}
                            checked={directOnlyState}
                          />
                        }
                        label="Show direct flights only"
                      />
                    )}
                    {!widgetMode && (
                      <FormControlLabel
                        className={'mt-1 lg:mt-0'}
                        disabled={isWebView() || disableHotelsSearch}
                        control={
                          <Checkbox
                            onChange={handleHotelSearchChange}
                            checked={searchHotelState}
                          />
                        }
                        label="Search hotels"
                      />
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </ThemeProvider>
  );
};
export default FlightSearch;
